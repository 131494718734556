import React from 'react'
import styled from 'styled-components'
import FadeUpWhenVisible from './FadeUpWhenVisible'
import BannerVideoBackground from './BannerVideoBackground'

const BannerVideoWrapper = styled.div`
  background: white;
`

interface BannerVideoContainerProps {
  width: number
}

const BannerVideoContainer = styled(
  FadeUpWhenVisible
)<BannerVideoContainerProps>`
  margin: 0 auto;
  max-width: ${({ width }) => width}px;
  position: relative;
`

const BannerVideoContent = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const BannerVideoTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-size: 30px;
  line-height: 36px;
  text-shadow: 0px 0px 5px #000000;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 65.22px;
    line-height: 78px;
    text-shadow: unset;
  }
`

const BannerVideoDescription = styled.p`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 27.22px;
    line-height: 33px;
    text-align: center;
    color: #ffffff;
  }
`

interface BannerVideoProps {
  title: React.ReactNode
  description?: React.ReactNode
  width: number
  height: number
  ratio: number
  cropFactor: number
  src: string
  type: string
  poster: string
}

const BannerVideo: React.FC<BannerVideoProps> = ({
  title,
  description,
  width,
  height,
  ratio,
  cropFactor,
  src,
  type,
  poster,
}) => (
  <BannerVideoWrapper>
    <BannerVideoContainer width={width}>
      <BannerVideoBackground
        width={width}
        height={height}
        ratio={ratio}
        cropFactor={cropFactor}
        src={src}
        type={type}
        poster={poster}
      />
      <BannerVideoContent>
        <BannerVideoTitle>{title}</BannerVideoTitle>
        {description && (
          <BannerVideoDescription>{description}</BannerVideoDescription>
        )}
      </BannerVideoContent>
    </BannerVideoContainer>
  </BannerVideoWrapper>
)

export default BannerVideo
