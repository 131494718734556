import { PageProps } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import BannerVideo from '../../components/BannerVideo'
import { LayoutProps } from '../../components/Layout.context'
import PartenairesContainer from '../../components/PartenairesContainer'
import PartenairesGrid from '../../components/PartenairesGrid'
import { aboutLink } from '../../data/menu.context'
import usePartnersQuery from '../../hooks/usePartnersQuery'

const PartenairesPage: React.FC<PageProps> = () => {
  const partners = usePartnersQuery()

  return (
    <>
      <Helmet>
        <meta
          name="description"
          title="La collaboration des experts du secteur des téléphones ! "
          content={`Save, leader de la réparation avec près de 200 magasins en France. Save est partenaire avec les grands constructeurs comme Samsung, Apple, Huawei et Xiaomi !`}
        />
      </Helmet>

      <PartenairesContainer>
        <BannerVideo
          title="Nos partenaires"
          description="La collaboration des experts"
          width={690}
          height={324}
          ratio={852 / 480}
          cropFactor={13}
          src="/videos/SaveLab.webm"
          type="video/webm"
          poster="/videos/Reparation.png"
        />

        <PartenairesGrid items={partners} />
      </PartenairesContainer>
    </>
  )
}

const layoutProps: LayoutProps = {
  title: 'Partenaires : Les experts de la téléphonie',
  breadcrumb: [
    { label: 'À propos', href: aboutLink },
    { label: 'Partenaires' },
  ],
}

export default Object.assign(PartenairesPage, {
  layoutProps,
})
